export default {
    data: () => ({}),
    methods: {
        checkPermissionExist: (user, permission) => {
            if (!user) return false;

            return (
                user.permissions.findIndex(item => item.name === permission) !==
                -1
            );
        },
    },
};

// export default {
//   data: () => ({}),
//   methods: {
//       checkPermissionExist: (user, permission) => {
//           console.log('permission', permission);
//           console.log('user', user);
//           if (!user) return false;

//           // Check if the user's role is super-administrator or administrator
//           if (
//               user.role === 'superadministrator' ||
//               user.role === 'administrator'
//           )
//               return true;

//           // Check if the user's role is teller and permission is 'teller-service'
//           if (user.role === 'teller' && permission === 'teller-service')
//               return true;

//           // Check if the permission exists in user's permissions
//           return (
//               user.permissions.findIndex(item => item.name === permission) !==
//               -1
//           );
//       },
//   },
// };
