// store/modules/nawecStatus.js
import axios from 'axios';
import * as types from '../mutation-types';

// State
export const state = {
    status: null, // This will hold the NAWEC status
};

// Getters
export const getters = {
    getStatus: state => state.status,
};

// Mutations
export const mutations = {
    [types.SET_NAWEC_STATUS](state, status) {
        state.status = status;
    },
};

// Actions
export const actions = {
    async fetchStatus({ commit }) {
        try {
            const response = await axios.get('/service-status/NAWEC');
            const status = response.data.is_live ? 'up' : 'down';
            commit(types.SET_NAWEC_STATUS, status);
        } catch (error) {
            console.error('Error fetching NAWEC status:', error);
            commit(types.SET_NAWEC_STATUS, 'down'); // Default to 'down' on error
        }
    },
};

// Export the module
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
