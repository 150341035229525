import Vue from 'vue';
import Chart from 'vue2-frappe';
import Multiselect from 'vue-multiselect';
import VModal from 'vue-js-modal';
import Swal from 'sweetalert2';
import axios from 'axios';
import vSelect from 'vue-select';

import 'vue-select/dist/vue-select.css';

import App from './App.vue';
import Pagination from './components/Pagination';
import Spinner from './components/Spinner.vue';
import store from './store';
import router from './router';

import './assets/main.css';
import './plugins';
import './components';

Vue.use(VModal);
Vue.component('pagination', Pagination);
Vue.component('v-select', vSelect);
Vue.component('spinner', Spinner);
Vue.use(Chart);
Vue.component('multiselect', Multiselect);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

Vue.config.productionTip = false;

window.Swal = Swal;
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
});
window.Toast = Toast;

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});
Vue.use(require('vue-moment'));

new Vue({
    store,
    router,
    ...App,
});
