var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800",attrs:{"id":"app-container"}},[_c('transition',{attrs:{"name":"slide"}},[(_vm.isSidebarOpen)?_c('div',{staticClass:"fixed inset-0 bg-gray-600 bg-opacity-95 z-40 lg:hidden"},[_c('button',{on:{"click":function($event){return _vm.toggleSidebar(false)}}},[_c('i',{staticClass:"bx bx-x text-3xl text-white absolute top-5 right-5"})])]):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.isSidebarOpen)?_c('div',{staticClass:"fixed flex flex-col top-0 left-0 transform z-50 w-64 bg-white h-full border-r lg:transform-none lg:static"},[_c('div',{staticClass:"flex items-center justify-center h-14 border-b"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{staticClass:"h-10 mb-2 mt-2 overflow-hidden",attrs:{"src":require("../assets/mainlogo.jpg")}})])],1),_c('div',{staticClass:"flex-grow overflow-auto"},[_c('ul',{staticClass:"flex flex-col py-4 space-y-1"},[_c('li',{staticClass:"px-5"}),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'dashboard-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-600 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/dashboard',
                            },attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Dashboard")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'deposit-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-600 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/deposits',
                            },attrs:{"to":"/deposits"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Deposits")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'transaction-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/new_transactions',
                            },attrs:{"to":"/new_transactions"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-transfer text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Transactions")])])],1):_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath ==
                                    '/branch_transactions',
                            },attrs:{"to":"/branch_transactions"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-transfer text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Transactions")])])],1),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'floats-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/manage_floats',
                            },attrs:{"to":"/manage_floats"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-bookmark-plus text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Manage Float")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'floats-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/commission_center',
                            },attrs:{"to":"/commission_center"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-bookmark-plus text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Commision Center")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'worksheets-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/worksheets',
                            },attrs:{"to":"/worksheets"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-briefcase-alt text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Worksheets")])])],1):_vm._e(),_c('li',{staticClass:"px-5"},[_c('div',{staticClass:"flex flex-row items-center h-8"},[_c('div',{staticClass:"text-sm font-light tracking-wide text-gray-500"})])]),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'cashcollection-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/cashcollection"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-collection text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Cash Collection")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'cashcollection-branch-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/branch_cashcollection"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-collection text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Branch Cash Collection")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'reconciliations-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/reconciliations"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Reconciliations")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'agents-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/agents',
                            },attrs:{"to":"/agents"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Agents")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'agents-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/superagents',
                            },attrs:{"to":"/superagents"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Super Agents")])])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath ==
                                    '/agent_transactions',
                            },attrs:{"to":"/agent_transactions"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-transfer text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Agent Transactions")])])],1),_c('li',{staticClass:"px-5"},[_c('div',{staticClass:"flex flex-row items-center h-8"},[_c('div',{staticClass:"text-sm font-light tracking-wide text-gray-500"})])]),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'staffs-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/staffs',
                            },attrs:{"to":"/staffs"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Staffs")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'branchs-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
                                'bg-gray-50 border-indigo-500':
                                    _vm.$route.fullPath == '/branches',
                            },attrs:{"to":"/branches"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",staticStyle:{"fill":"rgb(75 85 100)","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19 2H9c-1.103 0-2 .897-2 2v5.586l-4.707 4.707A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm-8 18H5v-5.586l3-3 3 3V20zm8 0h-6v-4a.999.999 0 0 0 .707-1.707L9 9.586V4h10v16z"}}),_c('path',{attrs:{"d":"M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 1h2v2H7z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Branches")])])],1):_vm._e(),(_vm.user.data.staff)?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/myprofile"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("My Profile")])])],1):_vm._e(),(
                            this.checkPermissionExist(
                                _vm.user.data,
                                'report-read'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/reports"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bxs-report text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Reports")])])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/settings/profile"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Settings")])])],1)])])]):_vm._e()]),_c('div',{staticClass:"flex-grow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }