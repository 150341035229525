var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed w-full flex items-center justify-between h-14 text-red z-10 __hide__to_print",attrs:{"id":"hide-to-print"}},[_c('div',{staticClass:"flex w-full justify-between items-center h-14 border-b bg-white dark:bg-gray-800 header-right"},[_c('div',{class:("flex items-center " + (_vm.isSidebarOpen && 'pl-64'))},[_c('button',{staticClass:"mx-3",on:{"click":_vm.toggleSidebar}},[_c('i',{staticClass:"bx bx-menu text-2xl"})]),_c('div',[(_vm.$route.name != 'tellerportal')?_c('router-link',{staticClass:"bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white hidden sm:block",attrs:{"to":"/tellerportal"}},[_vm._v(" Teller Portal ")]):_c('button',{staticClass:"bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white hidden sm:inline-block",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Close Teller Portal. ")])],1)]),_c('ul',{staticClass:"flex items-center"},[_c('li',{staticClass:"mr-6"},[_c('div',{staticClass:"flex items-center px-4 py-2 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105",class:_vm.nawecStatus === 'up' ? 'bg-green-100' : 'bg-red-100'},[_c('span',{staticClass:"w-4 h-4 rounded-full inline-block mr-2 animate-bounce",class:_vm.nawecStatus === 'up'
                                ? 'bg-green-500'
                                : 'bg-red-500'},[_c('span',{staticClass:"block w-full h-full rounded-full animate-ping",class:_vm.nawecStatus === 'up'
                                    ? 'bg-green-400'
                                    : 'bg-red-400'})]),_c('span',{staticClass:"font-semibold text-sm mr-1",class:_vm.nawecStatus === 'up'
                                ? 'text-green-700'
                                : 'text-red-700'},[_vm._v(" NAWEC ")]),_c('span',{staticClass:"font-bold text-sm",class:_vm.nawecStatus === 'up'
                                ? 'text-green-600'
                                : 'text-red-600'})])]),_c('li',{staticClass:"mx-4"},[_c('router-link',{staticClass:"bg-green-600 hover:bg-green-400 px-4 py-2 rounded text-white hidden sm:inline-block",attrs:{"to":"/deposit/agent/new"}},[_c('i',{staticClass:"bx bxs-coin-stack"}),_vm._v(" Deposit ")])],1),_c('li',[_c('b',[_vm._v(_vm._s(_vm.user.name))])]),_vm._m(0),_c('li',[_c('button',{staticClass:"flex items-center mr-4 hover:text-gray-500 text-black",on:{"click":_vm.logout}},[_c('span',{staticClass:"inline-flex mr-1"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"#333","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"}})])]),_vm._v(" Logout ")])]),_vm._m(1),_c('li',{staticClass:"pr-4"},[_c('h1',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.user.data.name))])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"block w-px h-6 mx-3 bg-gray-400 dark:bg-gray-700"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"block w-px mr-3 h-6 bg-gray-400 dark:bg-gray-700"})])}]

export { render, staticRenderFns }